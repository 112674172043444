import React from 'react';
import { func } from 'prop-types';

import { requiredFieldArrayCheckbox } from '../../../util/validators';
import { ExternalLink, FieldCheckboxGroup } from '../../../components';

import { FormattedMessage, intlShape } from '../../../util/reactIntl';

import css from './TermsAndConditions.module.css';
import NamedLinkNewTab from '../../../components/NamedLink/NamedLinkNewTab';

const KEY_CODE_ENTER = 13;

const TermsAndConditions = props => {
  const { onOpenTermsOfService, onOpenPrivacyPolicy, intl } = props;

  const handleClick = callback => e => {
    e.preventDefault();
    callback(e);
  };
  const handleKeyUp = callback => e => {
    // Allow click action with keyboard like with normal links
    if (e.keyCode === KEY_CODE_ENTER) {
      callback();
    }
  };

  const termsLink = (
    <NamedLinkNewTab name="CMSPage" params={{ pageId: 'agb' }} className={css.termsLink}>
      <FormattedMessage id="AuthenticationPage.termsAndConditionsTermsLinkText" />
    </NamedLinkNewTab>
  );

  const privacyLink = (
    <NamedLinkNewTab name="CMSPage" params={{ pageId: 'datenschutz' }} className={css.privacyLink}>
      <FormattedMessage id="AuthenticationPage.termsAndConditionsPrivacyLinkText" />
    </NamedLinkNewTab>
  );

  return (
    <div className={css.root}>
      <FieldCheckboxGroup
        name="terms"
        id="terms-accepted"
        optionLabelClassName={css.finePrint}
        options={[
          {
            key: 'tos-and-privacy',
            label: intl.formatMessage(
              { id: 'AuthenticationPage.termsAndConditionsAcceptText' },
              { termsLink, privacyLink }
            ),
          },
        ]}
        validate={requiredFieldArrayCheckbox(
          intl.formatMessage({ id: 'AuthenticationPage.termsAndConditionsAcceptRequired' })
        )}
      />
    </div>
  );
};

// TermsAndConditions.defaultProps = {};

TermsAndConditions.propTypes = {
  onOpenTermsOfService: func.isRequired,
  onOpenPrivacyPolicy: func.isRequired,
  intl: intlShape.isRequired,
};

export default TermsAndConditions;
