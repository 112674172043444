export const hearAboutOptions = [
  {
    key: 'friendsFamiliy',
    messageID: 'SignupForm.Options.friends',
    //label: 'Friends & Family',
  },
  {
    key: 'linkedin',
    messageID: 'SignupForm.Options.Linkedin',
    //label: 'Linkedin',
  },
  {
    key: 'tiktok',
    messageID: 'SignupForm.Options.TikTok',
    //label: 'TikTok',
  },
  {
    key: 'instagram',
    messageID: 'SignupForm.Options.Instagram',
    // label: 'Instagram',
  },
  {
    key: 'press',
    messageID: 'SignupForm.Options.Press',
    //label: 'Press',
  },
  {
    key: 'tv',
    messageID: 'SignupForm.Options.TV',
    //label: 'TV',
  },
  {
    key: 'travelAgency',
    messageID: 'SignupForm.Options.TravelAgency',
    // label: 'Travel Agency',
  },
  {
    key: 'others',
    messageID: 'SignupForm.Options.Others',
    // label: 'Others',
  },
];
